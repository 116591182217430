.workspace {
    display: block;
    width: 100vw;
    height: 100vh;
    color: rgb(27, 27, 27);
    font: .8125rem GothamPro, serif;
}
.workspace__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 0 10px lightgray;
}

.workspace__header.toolbar-is-open {
    height: 40px;
}
.workspace__left-side-header, .workspace__right-side-header {
    display: flex;
    align-items: center;
}
.workspace__logo {
    display: block;
    height: 23px;
    cursor: pointer;
}
.workspace__stick {
    width: 1px;
    height: 25px;
    background-color: #d8d8d8;
    margin: 0 35px;
}
.workspace__icons-container {
    display: flex;
}
.workspace__icon-wrapper {
    width: 14px;
    height: 14px;
    cursor: pointer;
}
.workspace__icon-wrapper:hover::after,
.workspace__download-icon-wrapper:hover::after {
    content: attr(data-title);
    display: block;
    position: absolute;
    top: 50px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    font-size: .8125rem;
    color: rgba(39,39,39,.6);
    box-shadow: 1px 3px 10px 1px lightgray;
    z-index: 100;
}
.workspace__download-icon-wrapper:hover::after {
    top: 30px;
    left: 20px;
}
.workspace__icon-wrapper:not(:last-of-type) {
    margin-right: 24px;
}
.workspace__icon {
    width: 100%;
    height: 100%;
}
.workspace__language-picker {
    cursor: pointer;
    position: relative;
}
.workspace__language-click-container {
    display: flex;
    align-items: flex-end;
}
.workspace__language {
    margin-right: 5px;
}
.workspace__language-picker_transparent_20 {
    color: rgba(27, 27, 27, .8);
}
.workspace__triangle, .workspace__triangle_active {
    width: 0; 
    height: 0;
    position: relative;
    border-bottom: none;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #1e1e1e;
    align-self: center;
    transition: .1s;
}
.workspace__triangle_active {
    border-top: none;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid #1e1e1e;
}
.workspace__language-list {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: .1s;
    box-shadow: 0 0 20px lightgray;
}
.workspace__language-list_active {
    top: 48px;
    opacity: 1;
    visibility: visible;
}
.workspace__language-list-nouse {
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
}
.workspace__language-list-item {
    background-color: white;
    width: 185px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: .875rem GothamPro;
    color: rgb(39,39,39,.6);
}
.workspace__language-list-item:not(:last-child) {
    border-bottom: 1px solid #fbfbfb;
}
.workspace__language-list-item:nth-child(2) {
    border-radius: 5px 5px 0 0;
}
.workspace__language-list-item:last-child {
    border-radius: 0 0 5px 5px;
}
.workspace__language-list-item:hover {
    color: #1e3553;
}
.workspace__contact {
    cursor: pointer;
    position: relative;
    margin-right: 15px;
}
.workspace__contact-img {
    display: block;
    width: 28px;
    height: 28px;
}
.workspace__contact-list {
    position: absolute;
    left: 90%;
    top: 0;
    transform: translateX(-85%);
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 20px lightgray;
    transition: .1s;
}
.workspace__contact-list_active {
    top: 59px;
    opacity: 1;
    visibility: visible;
}
.workspace__contact-list-nouse {
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    top: 0;
    left: 77.5%;
    transform: translate(-50%, -100%);
}
.workspace__contact-list-item {
    background-color: white;
    width: 345px;
    height: 40px;
    display: flex;
    align-items: center;
    font: .875rem GothamPro;
    color: rgb(39,39,39,.6);
    padding-left: 25px;
}
.workspace__contact-list-item:not(:last-child) {
    border-bottom: 1px solid #fbfbfb;
}
.workspace__contact-list-item:nth-child(2) {
    border-radius: 5px 5px 0 0;
}
.workspace__contact-list-item:last-child {
    border-radius: 0 0 5px 5px;
}
.workspace__contact-list-item:hover {
    color: #1e3553;
}
.workspace__contact-list-img {
    margin-right: 12px;
    color: black;
}
a.workspace__contact-list-item {
    text-decoration: none;
}
.workspace__profile {
    cursor: pointer;
    position: relative;
    margin-right: 15px;
}
.workspace__profile-img {
    display: block;
    width: 28px;
    height: 28px;
}
.workspace__profile-list {
    position: absolute;
    left: 90%;
    top: 0;
    transform: translateX(-85%);
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 20px lightgray;
    transition: .1s;
}
.workspace__profile-list_active {
    top: 59px;
    opacity: 1;
    visibility: visible;
}
.workspace__profile-list-nouse {
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    top: 0;
    left: 77.5%;
    transform: translate(-50%, -100%);
}
.workspace__profile-list-item {
    background-color: white;
    width: 185px;
    height: 40px;
    display: flex;
    align-items: center;
    font: .875rem GothamPro;
    color: rgb(39,39,39,.6);
    padding-left: 25px;
}
.workspace__profile-list-item:not(:last-child) {
    border-bottom: 1px solid #fbfbfb;
}
.workspace__profile-list-item:nth-child(2) {
    border-radius: 5px 5px 0 0;
}
.workspace__profile-list-item:last-child {
    border-radius: 0 0 5px 5px;
}
.workspace__profile-list-item:hover {
    color: #1e3553;
}
.workspace__profile-list-img {
    margin-right: 12px;
    color: black;
}
.workspace__sidebar {
    width: 235px;
    position: fixed;
    left: 0;
    top: 60px;
    height: calc(100vh - 60px);
    z-index: 2;
}

.workspace__sidebar.toolbar-is-open{
    width: 235px;
    position: fixed;
    left: 0;
    top: 40px;
    height: calc(100vh - 40px);
    z-index: 2;
}
.workspace__sidebar-fullscreen-container {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 30px;
}
.workspace__sidebar-fullscreen-container.toolbar-is-open {
    height: calc(100vh - 60px);
}

.workspace__dashboards-container {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .workspace__dashboards-container {
        max-height: calc(100% - 180px);
        margin-bottom: 10px;
    }
}
.workspace__dashboards-name {
    color: black;
    font-size: 1rem;
    font-weight: 600;
    padding: 0 20px 20px 24px;
}
.workspace__dashboards-selected, .workspace__dashboards-list-dashboard, .workspace__dashboards-list-workbook {
    padding: 9px 0;
}
.workspace__horizontal-line {
    width: calc(100% - 44px);
    height: 1px;
    background-color: #ebebeb;
    margin: 5px 20px 10px 24px;
    flex: 0 0 auto;
}
.workspace__dashboards-list {
    width: 100%;
    flex: 1 1 auto;
    overflow-y: auto;
    transition: .1s;
}
.workspace__dashboards-list::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
}
.workspace__dashboards-list::-webkit-scrollbar-thumb { 
    width: 2px;
    background-color: #e9e9e9;
}
.workspace__dashboards-list-dashboard, .workspace__dashboards-list-workbook {
    padding-left: 44px;
    color: rgba(39,39,39,.6);
    cursor: pointer;
}
.workspace__dashboards-list-dashboard {
    position: relative;
}
.workspace__dashboards-list-dashboard_active::before {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: #1e3553;
}

.workspace__dashboards-list-dashboard_active {
    background-color: #f6f6f6;
}

.workspace__dashboards-list-workbook {
    padding-left: 24px;
    color: #165193;
    font-weight: 500;
}
.workspace__dashboards-list-dashboard:hover {
    color: #1e3553;
}
.workspace__dashboards-list-dashboard:hover, .workspace__dashboards-list-workbook:hover {
    background: #f8fafb;
}
.workspace__dashboards-list-site {
    font-weight: bold;
}
.workspace__content {
    position: fixed;
    top: 60px;
    left: 235px;
    width: calc(100vw - 235px);
    height: calc(100vh - 60px);
    overflow: auto;
    background-color: #ffffff;
}

.workspace__content.toolbar-is-open {
    top: 40px;
    height: calc(100vh - 40px);
}
.workspace__content-inside {
    display: inline-block;
}
.workspace__aside-part2 {
    padding: 20px 20px 0 24px;
    flex: 0 0 auto;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .workspace__aside-part2 {
        position: absolute;
        bottom: 10px;
    }
}
.workspace__download {
    color: black;
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: 600;
}
.workspace__download-description {
    margin-bottom: 15px;
    color: rgba(39,39,39,.6);
}
.workspace__download-icons-block {
    display: flex;
    margin-bottom: 25px;

}
.workspace__download-icon-wrapper {
    position: relative;
    height: 20px;
    box-sizing: content-box;
    padding: 7px;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    cursor: pointer;
    transition: .1s;
}
.workspace__download-icon-wrapper:not(:last-child) {
    margin-right: 10px;    
}
.workspace__download-icon-wrapper_pdf:hover {
    border-color: #d2171f;
}
.workspace__download-icon-wrapper_image:hover {
    border-color: #f8c572;
}
.workspace__download-icon-wrapper_data:hover {
    border-color: #225a99;
}
.workspace__download-icon {
    max-height: 100%;
    max-width: 100%;    
}
.workspace__aside-footer {
    color: rgba(79,85,93,.35);
    font-size: .5625rem;
    height: 20px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    flex: 0 0 auto;
}
.fade {
    transition: opacity 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  
.collapse:not(.show) {
    display: none;
  }
  
  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }

.workspace__mobile-menu {
    display: none;
}

.workspace__sidebar.menu-is-open{
    display: block;
}

.workspace__logo-without_text {
    display: none;
}

.loading-animation {
    width:100px;
}

#search-site {
    display: block;
    width: calc(100% - 42px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 5px 20px 10px 24px;
    font: .8125rem GothamPro,serif;
}

@media screen and (max-width: 1200px) {
    .ReactModal__Content.ReactModal__Content--after-open {
        width: 90% !important;
    }
}


@media screen and (max-width: 1024px){
    .ReactModal__Content.ReactModal__Content--after-open {
        padding: 40px !important;
    }

    .workspace__mobile-menu {
        display: block;
    }

    .comment:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
    }
    .workspace__icon-wrapper:first-of-type, .workspace__icon-wrapper:nth-of-type(3), .workspace__icon-wrapper:last-of-type{
        display: none;
    }


    .workspace__logo {
        margin-left: 35px;
    }

    .workspace__sidebar{
        display: none;
    }

    .workspace__sidebar.menu-is-open{
        display: block;
        background-color: white;
        width: 90%;
        border-right: 1px solid #f6f6f6;
    }

    .workspace__content{
        left: 0;
        width: 100%;
    }

    .workspace__content.menu-is-open {
        overflow: hidden;
    }

    .workspace__dashboards-selected, .workspace__dashboards-list-dashboard, .workspace__dashboards-list-workbook {
        padding-bottom: 18px;
    }

    .workspace__download-icon-wrapper:not(:last-child) {
        margin-right: 30px;
    }

    .workspace__download-icon-wrapper {
        padding-right: 25px;
        padding-left: 25px;
    }

    .workspace__language.workspace__language-picker_transparent_20 {
        display: none;
    }

    .workspace__logo {
        display: none;
    }

    .workspace__logo-without_text {
        display: block;
        height: 26px;
        cursor: pointer;
        padding-left: 20px;
    }

    .workspace__stick{
        margin: 0 20px;
    }

    .workspace__icon-wrapper:not(:last-of-type) {
        margin-right: 0px;
    }

    .workspace__download-description {
        display:none;
    }

    .workspace__sidebar-fullscreen-container{
        padding-top: 20px;
    }

    .workspace__dashboards-name {
        padding: 0 20px 10px 24px;
    }

    .workspace__aside-part2 {
        padding: 10px 20px 0 24px
    }

    .workspace__download {
        margin-bottom: 10px;
    }

    .workspace__download-icons-block {
        margin-bottom: 10px;
    }
}

@media screen and (max-height: 480px){
    .workspace__dashboards-container{
        overflow: visible;
    }

    aside.workspace__sidebar {
        overflow-y: auto;
    }

    aside.workspace__sidebar::-webkit-scrollbar {
        width: 2px;
        background-color: transparent;
    }
    aside.workspace__sidebar::-webkit-scrollbar-thumb {
        width: 2px;
        background-color: #e9e9e9;
    }

    .workspace__download-description {
        display: none;
    }

    .workspace__dashboards-name {
        padding: 0 20px 10px 24px;
    }

    .workspace__aside-part2 {
        padding: 10px 20px 0 24px
    }
    .workspace__download {
        margin-bottom: 10px;
    }

    .workspace__download-icons-block {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 480px){
    .ReactModal__Content.ReactModal__Content--after-open {
        padding: 20px !important;
        width: 95% !important;
        font-size: 14px !important;
    }

    .workspace__download-icons-block {
        justify-content: center;
    }

    .workspace__download-icon-wrapper {
        padding-right: 5px;
        padding-left: 5px;
    }

    .workspace__stick{
        margin: 0 5px;
    }

    .loading-animation {
        width:50px;
        margin-right: 5px;
    }
    .workspace__header {
        padding: 0 10px;
    }

    .workspace__logo-without_text{
        padding-left: 10px;
    }
}

@media screen and (max-width: 320px){
    .workspace__download-icons-block {
        justify-content: center;
    }

    .workspace__download-icon-wrapper {
        padding-right: 5px;
        padding-left: 5px;
        margin-right: 5px !important;
    }
}