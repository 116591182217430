.ReactModal__Overlay li {
    margin-top: 5px;
    margin-bottom: 5px;
}

.ReactModal__Overlay h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
}

.ReactModal__Overlay ol {
    line-height: 1.5
}
