

/* Reset CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box 
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  font: 16px GothamPro, sans-serif;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  html {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

@font-face {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: medium;
	src: url('./assets/fonts/GothaProMed.woff') format('woff');
}
@font-face {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: normal;
	src: url('./assets/fonts/GothaProReg.woff') format('woff');
}
@font-face {
  font-family: 'GothamPro';
  font-style: italic;
  font-weight: medium;
	src: url('./assets/fonts/GothamProMediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: bold;
	src: url('./assets/fonts/GothaProBol.woff') format('woff');
}
@font-face {
  font-family: 'GothamPro';
  font-style: italic;
  font-weight: normal;
	src: url('./assets/fonts/GothamProMediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'GothamPro';
  font-style: normal;
  font-weight: lighter;
	src: url('./assets/fonts/GothaProLig.woff') format('woff');
}
@font-face {
  font-family: 'GothamPro';
  font-style: italic;
  font-weight: lighter;
	src: url('./assets/fonts/GothaProLigIta.woff') format('woff');
}