#tabusers {
    border-collapse: separate !important;
}

#tabusers th {
    width: 50%;
    font-weight:bold;
    border-bottom: 2px solid gray;
    background-color: white;
}

#wrapperdiv {
    margin-top: 15px;
    flex-grow: 1;
    overflow-y: scroll;
    width: 100%;
    min-width: 100%;
}

#tabusers {
    width:100%;
}

#tabusers .datarow {
    cursor: pointer;
}

#tabusers .datarow:hover {
    background-color: lightblue;
}

#tabusers .datarow td {
    padding: 5px;
}

#tabusers thead {
    position: sticky;
    top: 0px;
    z-index: 2;
}

.spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px; /* Full screen height for centering */
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light outer ring */
    border-top: 4px solid #3498db; /* Blue animated part */
    border-radius: 50%; /* Rounded edges */
    animation: spin 1s linear infinite; /* Infinite rotation */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#filteruser {
    display: inline-block;
    width: calc(100% - 142px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin: 5px 20px 10px 24px;
    font: .8125rem GothamPro,serif;
}

.btn-danger {
    color: #fff !important;
    background-color: #dc3545;
    border-color: #dc3545;
    margin-left: 15px;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.impersonate-warning{
    margin-top: 15px;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    flex-basis: 300px;
    max-height: 70px;
}