.login__background {
    z-index: -100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.login__container {
    padding: 30px 40px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    color: white;
    overflow: hidden;
}
.login__header {
    display: flex;
    justify-content: center;
}
.login__logo-wrapper {
    cursor: pointer;
    width: 300px;
    height: 26px;
}
.login__logo-img {
    width: 100%;
}
.login__content, .access_denied__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.login__greeting {
    width: 900px;
    color: #D8D8D8;
    font: lighter 3.5rem GothamPro, serif;
    line-height: 1.2;
    letter-spacing: -1px;
    text-transform: uppercase;
    text-align: center;
}
.login__greeting_bold {
    font-weight: bold;
    font-family: GothamPro, serif;
    color: white;
}
.login__greeting_small {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: GothamPro, serif;
    color: white;
    font-size: 1.5rem;
    text-transform: none;
}
.login__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}
.login__footer-left-side {
    display: flex;
    justify-content: space-between;
}
.login__gray_item {
    font: .8125rem GothamPro, serif;
    color: rgba(255, 255, 255, .65);
    text-decoration: none;
}
.login__gray_item:not(:last-child) {
    margin-right: 20px;
}

a.login__gray_item:hover, a.login__gray_item:active, a.login__gray_item:focus  {
    text-decoration: none;
    color: #23527c;
}
.login__footer-middle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px;
}
.login__footer-right-side {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 40px;
}
.login__footer-item {
    width: 300px;
}
.login__cursor-pointer {
    cursor: pointer;
    display: flex;
}
.login__language-container {
    display: flex;
    flex-direction: column-reverse;
    height: 15px;
    position: relative;
    margin-right: 10px;
}
.login__language, .login__language-other, .login__language-other_active, .login__contact {
    font: .8125rem GothamPro, serif;
    color: white;
    text-align: right;
}
.contact-large a {
    font: 1.5rem GothamPro, serif;
    color: white;
    text-align: right;
    text-decoration: none;
}
.login__language a, .login__language-other a, .login__language-other_active a {
    color: white;
    text-decoration: none;
}

.login__contact {
    text-align: right;
}
.login__language-other, .login__language-other_active {
    position: relative;
    left: 0;
    visibility: hidden;
    transition: .2s;
    color: transparent;
}
.login__language-other_active {
    visibility: visible;
    color: white;
}
.login__lang-picker-triangle, .login__lang-picker-triangle_active {
    width: 0; 
    height: 0;
    position: relative;
    border-top: none;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid white;
    align-self: center;
    transition: .2s;
}
.login__lang-picker-triangle_active {
    border-bottom: none;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid white;
}

.login__button {
    padding-bottom: 20px;
}

@media all and (min-width: 480px) {

    .login__button {
      padding-top: 60px;
      padding-bottom: 25px;
      margin: 0 auto;
      max-width: 320px;
    }
}

@media screen and (max-width: 960px){
    .login__greeting {
        width: 90%;
        font-size: 2.5rem;
        margin-bottom: 30px;
    }

    .access_denied__content {
        width: 90%;
    }

    .login__content {
        left: 0%;
        transform: translate(0%, -50%);
    }

}

@media screen and (max-width: 480px){
    .login__greeting {
        font-size: 1.5rem;
    }
    .login__greeting_small {
        font-size: 1.2rem;
    }
    .contact-large a {
        font-size: 1.2rem;
    }
}

@media screen and (max-height: 500px){
    .login__greeting {
        font-size: 1.5rem;
    }
}

@media screen and (max-height: 380px) {
    .login__button {
        padding: 0px 0;
    }

    .login__greeting {
        margin-bottom: 10px;
    }

    .login__greeting_small {
        font-size: 1.2rem;
    }
    .contact-large a {
        font-size: 1.2rem;
    }
}

@media screen and (max-height: 260px){
    .login__greeting {
        font-size: 1.3rem;
    }
}