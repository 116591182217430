.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.loadingindicatoroverlay {
  z-index: 9000;
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
}